// src/pages/Pocetna.js
import React from "react";
import PocetnaBaner from "./Pocetna/PocetnaBaner";
import SectionNavbar from "../components/NavigacijaSekcije";
import PrvaSekcija from "./Pocetna/PrvaSekcija";
import Onama from "./Pocetna/Onama";
import Vijesti from "./Pocetna/Vijesti";
import Obavijesti from "./Pocetna/Obavijesti";
import Projekti from "./Pocetna/Projekti";
import { Outlet } from "react-router-dom";
import DekanatIzjave from "./Pocetna/DekanatIzjave";
import Ciklusi from "./Pocetna/Ciklusi";

import Odsjeci from "./Pocetna/Odsjeci";
import Kontakt from "./Pocetna/Kontak";
import { useRef } from "react";

function Pocetna() {
  const bannerRef = useRef(null); // Ref for Onama section

  const items = [
    { name: "O nama", nameEng: "About us", link: "#onama" },
    { name: "Novosti", nameEng: "News", link: "#novosti" },
    { name: "Obavijesti", nameEng: "Announcements", link: "#obavjestenja" },
    { name: "Projekti", nameEng: "Projects", link: "#projekti" },
    { name: "Riječi uprave", nameEng: "Dean's word", link: "#rijeciuprave" },
    { name: "Ciklusi", nameEng: "Cycles", link: "#ciklusi" },
    { name: "Odsjeci", nameEng: "Departments", link: "#odsjeci" },
    { name: "Kontakt", nameEng: "Contact", link: "#kontakt" },
  ];

  // Meta Tags
  const lang = localStorage.getItem("language");
  document.title =
    lang == "bs"
      ? "Mašinski fakultet - Univerzitet u Sarajevu"
      : "Faculty of Mechanical Engineering - University of Sarajevo";

  document
    .querySelector('meta[name="description"]')
    .setAttribute(
      "content",
      lang == "bs"
        ? "Web stranica Mašinskog fakulteta - Univerzitet u Sarajevu"
        : "Web site of Faculty of Mechanical Engineering - University of Sarajevo"
    );

  return (
    <div>
      <div ref={bannerRef}>
        <PocetnaBaner />
      </div>
      <SectionNavbar items={items} observeRef={bannerRef} />
      <PrvaSekcija />
      <Onama />
      <Vijesti />
      <Obavijesti />
      <Projekti />
      <DekanatIzjave />
      <Ciklusi />
      <Odsjeci />
      <Kontakt />
      <Outlet />
    </div>
  );
}

export default Pocetna;
