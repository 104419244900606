import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import {
  FaMapMarkerAlt,
  FaCity,
  FaGlobe,
  FaEnvelope,
  FaPhone,
  FaClock,
} from "react-icons/fa";
import ButtonComponent from "../../components/ButtonComponent";
import { Link } from "react-router-dom";
import styles from "./Kontakt.module.css"; // Import the CSS module

const Kontakt = () => {
  return (
    <div className="container__wrapper bg-white" id="kontakt">
      <Container>
        <div className="d-flex align-items-center justify-content-between flex-wrap mb-3">
          <div className="mb-3">
            <h2 className="section_title">
              {localStorage.getItem("language") === "bs"
                ? "Kontakt"
                : "Contact"}
            </h2>
            <p className="section_subtitle">
              {localStorage.getItem("language") === "en"
                ? "Here you can find all the necessary information to contact us."
                : "Ovdje možete pronaći sve potrebne informacije kako biste nas pronašli ili kontaktirali. Radujemo se vašem dolasku i upitu!"}
            </p>
          </div>

          <ButtonComponent
            label={
              localStorage.getItem("language") === "bs"
                ? "Pošalji poruku →"
                : "Send a message →"
            }
            color="#ffffff"
            backgroundColor="#051435"
            disabled={true}
            className="button__style"
          />
        </div>

        <Row className="mt-5">
          {/* First Card */}
          <Col md={4} sm={12} className="mb-4">
            <Card className={styles.cardCustom}>
              <Card.Body>
                <h5 className="card__title  mb-4">
                  {localStorage.getItem("language") === "en"
                    ? "Address"
                    : "Adresa"}
                </h5>
                <p className="card__subtitle">
                  <FaMapMarkerAlt className="me-2" /> Vilsonovo šetalište 9
                </p>
                <p className="card__subtitle">
                  <FaCity className="me-2" /> 71000 Sarajevo
                </p>
                <p className="card__subtitle">
                  <FaGlobe className="me-2" /> Bosna i Hercegovina
                </p>
              </Card.Body>
            </Card>
          </Col>

          {/* Second Card */}
          <Col md={4} sm={12} className="mb-4">
            <Card className={styles.cardCustom}>
              <Card.Body>
                <h5 className="card__title mb-4">
                  {localStorage.getItem("language") === "en"
                    ? "Faculty"
                    : "Fakultet"}
                </h5>
                <p className="card__subtitle">
                  <FaEnvelope className="me-2" /> info@mef.unsa.ba
                </p>
                <p className="card__subtitle">
                  <FaPhone className="me-2" /> +387 33 729 813
                </p>
                <p className="card__subtitle">
                  <FaClock className="me-2" /> Pon - Pet / 09:00 - 15:00
                </p>
              </Card.Body>
            </Card>
          </Col>

          {/* Third Card */}
          <Col md={4} sm={12} className="mb-4">
            <Card className={styles.cardCustom}>
              <Card.Body>
                <h5 className="card__title  mb-4">
                  {" "}
                  {localStorage.getItem("language") === "en"
                    ? "Library"
                    : "Biblioteka"}
                </h5>
                <p className="card__subtitle">
                  <FaEnvelope className="me-2" /> biblioteka@mef.unsa.ba
                </p>
                <p className="card__subtitle">
                  <FaPhone className="me-2" /> +387 33 729 813
                </p>
                <p className="card__subtitle">
                  <FaClock className="me-2" /> Pon - Pet / 09:00 - 15:00
                </p>
              </Card.Body>
            </Card>
          </Col>

          {/* Fourth Card */}
          <Col md={4} sm={12} className="mb-4">
            <Card className={styles.cardCustom}>
              <Card.Body>
                <h5 className="card__title  mb-4">
                  {localStorage.getItem("language") === "en"
                    ? "Student Service"
                    : "Studentska služba"}
                </h5>
                <p className="card__subtitle">
                  <FaEnvelope className="me-2" /> studentska_sluzba@mef.unsa.ba
                </p>
                <p className="card__subtitle">
                  <FaPhone className="me-2" /> +387 33 729 813
                </p>
                <p className="card__subtitle">
                  <FaClock className="me-2" /> Pon - Pet / 09:00 - 12:00
                </p>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Kontakt;
